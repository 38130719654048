<template>
	<a
		v-show="url"
		:href="url"
		:class="{
			mobile: mobileMode,
			desktop: !mobileMode,
		}"
		target="_blank"
		class="pdf-download-card"
		download
	>
		<h2
			class="card-title"
			v-html="$t('get_pdf')"
		/>
		<span v-if="size" class="details">
			<template
				v-if="type"
			>
				{{type}},
			</template>
			{{size}}
		</span>
	</a>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { ALL_PROJECTS_ROUTE_NAME } from '@/constants/router-strings';

export default {
	name: 'PdfDownloadCard',

	props: {
		cardData: {
			type: Object,
			default: null,
		},
	},

	computed: {
		// ...mapState('projects', { projects: 'list' }),
		// ...mapState(['route']),
		...mapGetters('browser', ['mobileMode']),

		url() { return this.cardData.url; },

		size() {
			let size = this.cardData.size;
			if (!size) return null;

			return `${ (size / 1024 / 1024).toFixed(2) }MB`
		},

		type() {
			if (!this.url) return null;
			let suffix = this.url.replace(/\?.*$/i, '').split('.').pop();
			if (suffix.length > 7) return null;
			return suffix.toUpperCase();
		}
	},
};
</script>

<style lang="sass" scoped>
.pdf-download-card
	position: relative
	overflow: hidden
	color: inherit
	background-color: #ffff00
	text-decoration-color: transparent

	&:hover
		text-decoration-color: currentColor

	&.mobile
		display: flex
		align-items: center
		justify-content: space-between
		padding: 0 25px
		box-sizing: border-box
		height: 75px

	&.desktop
		padding: 30px 40px 40px


.card-title
	margin: 0

	.mobile &
		@extend %h2-mobile

	.desktop &
		@extend %h2-desktop

.details
	.mobile &
		@extend %p-mobile
		text-align: right



	.desktop &
		@extend %p-desktop
		position: absolute
		left: 40px
		bottom: 35px
</style>
