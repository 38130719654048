<template>
	<svg
		:width="size"
		:height="size"
		:viewBox="`0 0 ${ size } ${ size }`"
		:style="{
			'--radius': `${ radius }px`,
			'--rotation-time': `${ rotationTime }s`,
			'--shrinking-time': `${ shrinkingTime }s`,
			'--size': `${ size }px`,
			'--width': `${ width }px`,
		}"


		xmlns="http://www.w3.org/2000/svg"
		class="circle-rotator"
	>
		<circle
			class="circle-rotator__glyph"
			:cx="size / 2"
			:cy="size / 2"
			:r="(size - width) / 2 - .5"
			:stroke-width="width"
			:stroke-linecap="linecap"
			stroke="currentColor"
			fill="none"
		/>
	</svg>
</template>


<script>
export default {
	name: 'CircleRotator',

	props: {
		size: {
			type: Number,
			default: 90,
		},

		width: {
			type: Number,
			default: 3,
		},

		linecap: {
			type: String,
			default: 'round'
		},

		shrinkingTime: {
			type: Number,
			default: 1.5,
		},

		rotationTime: {
			type: Number,
			default: 1.5
		},
	},

	computed: {
		radius() {
			return (this.size - this.width) / 2 - .5;
		},
	},
};
</script>


<style lang="sass" scoped>
.circle-rotator
	--radius: 43px
	--rotation-time: 1.5s
	--shrinking-time: 1.5s
	--size: 90px
	--width: 3px

	will-change: transform, opacity
	transform-origin: center
	animation-name: circle-rotator__animation_rotation
	animation-iteration-count: infinite
	animation-duration: var(--rotation-time)
	animation-timing-function: linear
	animation-fill-mode: both


.circle-rotator__glyph
	will-change: transform, stroke-dashoffset
	transform-origin: center

	stroke-dasharray: calc(var(--radius) * 2 * 3.1416)
	stroke-dashoffset: calc(var(--radius) * 2 * 3.1416)

	animation-duration: var(--shrinking-time)
	animation-fill-mode: both
	animation-iteration-count: infinite
	animation-name: circle-rotator__animation_stroke
	animation-timing-function: ease-in-out


@keyframes circle-rotator__animation_rotation
	from
		transform: rotateZ(0deg)

	to
		transform: rotateZ(360deg)


@keyframes circle-rotator__animation_stroke
	0%
		stroke-dashoffset: calc(var(--radius) * 2 * 3.1416 * .25)
		transform: rotateZ(0deg)

	50%
		stroke-dashoffset: calc(var(--radius) * 2 * 3.1416)
		transform: rotateZ(315deg)

	100%
		stroke-dashoffset: calc(var(--radius) * 2 * 3.1416 * .25)
		transform: rotateZ(360deg)
</style>
