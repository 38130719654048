<template>
	<div
		:class="{
			mobile: mobileMode,
			desktop: !mobileMode
		}"
		class="filter-card"
	>
		<p class="header" v-html="groupTitle" />

		<tag-list
			:tags="currentTags"
			clickable
			separator="/"
		/>
	</div>
</template>

<script>
import { cleanOfBlockTags } from "@/utilites.js";
import { mapState, mapGetters } from "vuex";
import TagList from '@/components/tag-list.vue';


export default {
	name: 'TagsCard',
	props: {
		cardData: {
			type: Object,
			required: false
		}
	},

	components: {
		TagList,
	},

	computed: {
		...mapState("tags", { tags: "tags", groups: "groups", group: "defaultGroup" }),
		...mapGetters("tags", ["getTagsByGroup", "getGroup"]),
		...mapGetters("browser", ["mobileMode"]),
		...mapState(["route"]),
		groupTitle() {
			const group = this.getGroup(this.group);
			if (!group) return null;
			return group.title;
		},

		currentTags() {
			let tags = this.tags.filter(tag => tag.group === this.group);
			if (!tags) return tags;
			let formatedTags = [
				{
					slug: "",
					title: this.$t("filter_all"),
					to: { query: {} },
				},
				...tags
			];
			return formatedTags;
		},
	},
};
</script>

<style lang="sass" scoped>
.filter-card
	@extend %p-desktop
	background-color: $clr__light-bg
	padding: 30px 25px 30px 40px

.tags-container
	display: flex
	flex-direction: row
	flex-wrap: wrap
	align-items: flex-start
	justify-content: flex-start

.header
	margin: 0
	padding: 0
	font-size: inherit
	font-family: inherit
	color: #6d7278
</style>
