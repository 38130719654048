<template>
	<div
		:class="{
			'grid-layout_mobile': mobileMode,
			'grid-layout_desktop': !mobileMode,
		}"
		:style="{
			gridTemplateAreas: !mobileMode && templateAreas
		}"
		class="grid-layout"
	>
		<grid-layout-tile
			v-for="(tile, index) in tiles"
			:key="getTileKey(tile, index)"
			:tile="tile"
			:style="getTileStyle(tile, index)"
			class="grid-layout__cell"
		/>
		<slot />
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { VGrid } from '@/utilites.js';
import GridLayoutTile from '@/components/grid-layout-tile.vue';
import {
	// くあうくつ
	REF_ROOT_FONT_SIZE,
	REF_VP_HEIGHT,
	REF_CELL_WIDTH,
	REF_CELL_HEIGHT,
	REF_CELL_HEIGHT_MOBILE,
} from '@/constants/mesures.js';


export default {
	name: 'GridLayout',
	data() {
		return {};
	},
	components: {
		GridLayoutTile
	},
	props: {
		tiles: {
			type: Array,
			required: true,
		},

		cellHeight: {
			type: Number,
			required: false,
			default: REF_CELL_HEIGHT
		},

		customColumnsCount: {
			type: [Function, Number],
			required: false,
			default: null
		}
	},
	computed: {
		...mapState('browser', {windowHeight: 'height', windowWidth: 'width'}),
		...mapGetters('browser', ['aspectRatio', 'mobileMode']),

		columnsCount() {
			if (typeof this.customColumnsCount === 'number' && this.customColumnsCount > 0) {
				return this.customColumnsCount;
			}

			if (typeof this.customColumnsCount === 'function') {
				return this.customColumnsCount();
			}

			return this._columnsCount;
		},

		_columnsCount() {
			// Math according to another style-section
			let count = Math.round(this.windowWidth / 330);
			return count;
		},

		vGrid() {
			// if (this.mobileMode) return null;

			const vGrid = new VGrid(this.columnsCount);
			for (let i = 0, n = this.tiles.length; i < n; i++) {
				let cell = this.tiles[i];
				let space = {
					x: vGrid.shortest,
					y: vGrid[vGrid.shortest].length,
					maxWidth: vGrid.availableLineLength,
				};
				let maxCellLength = Math.min(space.maxWidth, cell.data.cell.w)
				for (let x = space.x, limX = space.x + maxCellLength; x < limX; x++) {
					vGrid[x].push(...Array(cell.data.cell.h).fill(`tile${i}`));
				}
			}
			return vGrid;
		},

		templateAreas() {
			return this.vGrid.transponed
				.map(row => `"${row.map(tileName => typeof tileName === 'undefined' ? '.' : tileName).join(' ')}"`)
				.join('\n');
		},
	},
	methods: {
		getTileStyle(tile, index) {
			const tileStyle = {};

			const baseHeight = this.cellHeight || (this.mobileMode
				? REF_CELL_HEIGHT_MOBILE
				: REF_CELL_HEIGHT);

			if (!this.mobileMode || tile.type !== 'article-card') {
				tileStyle['height'] = `${baseHeight * tile.data.cell.h}px`;
			}

			tileStyle['grid-area'] = this.mobileMode ? '' : `tile${index}`;

			// HACK: for screens from 750 to 1440 px width and custom columns count
			let isCustomColumnsCount = this._columnsCount < this.columnsCount;
			if (isCustomColumnsCount) {
				tileStyle['padding'] = '30px 10px 40px 20px';
				tileStyle['font-size'] = '25px';
			}

			return tileStyle;
		},

		getTileKey(tile, index) {
			switch (tile.type) {
				case 'project-card':
				case 'image-card':
				case 'video-card':
					return tile.data.id;

				case 'all-projects-card':
					return 'all-projects';

				case 'pdf-download-card':
					return tile.data.size;

				default:
					return index;
			}
		}
	},
};

</script>

<style lang="sass">
// .grid-layout

.grid-layout_mobile
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: flex-start

.grid-layout_desktop
	display: grid
	grid-auto-columns: 1fr
	grid-auto-rows: 1fr

	& > *
		justify-self: stretch
		align-self: stretch

	// There are should be 4 columns @ 1440 / 810
	// @for $i from 17 through 1
	// 	$max-width: ($i + .75) * $grd__cell-width

	// 	@media screen and (max-aspect-ratio: #{$max-width} / #{$__ref-vp-height})
	// 		// `$i` columns
	// 		grid-template-columns: repeat(#{$i}, 1fr)

.grid-layout__cell
	box-sizing: border-box

	.grid-layout_mobile &
		$tiles-on-screen: 2.5
		// min-height: calc((100vh - #{rslv($hp__height)}) / #{$tiles-on-screen})
		// max-height: calc(100vh - #{rslv($hp__height)})
		// min-height: calc(#{rslv(100vh / 3)} - #{rslv($hp__height / 3)})
</style>
