<template>
	<div
		:class="{
			// 'loading': loading,
			// 'error': error,
		}"
		class="video-card"
	>
		<plyr-wrapper
			ref="videoPlyr"
			:src="src"
			:poster="poster"
			class="player"
			preload="metadata"
			play-button
		/>
	</div>
</template>

<script>
// import 'video.js'
import PlyrWrapper from '@/components/plyr-wrapper';

export default {
	name: 'VideoCard',
	data() {
		return {
			// loading: true,
			// error: false,
		};
	},

	components: {
		PlyrWrapper,
	},

	props: {
		cardData: {
			type: Object,
			required: true,
			validator(data) {
				return data.value.video.file || data.value.url;
			},
		},
	},

	computed: {
		poster() {
			if (!this.cardData.value.image || !this.cardData.value.image.file) return;
			return this.cardData.value.image.file;
		},

		src() {
			return this.cardData.value.video.file
				|| this.cardData.value.url
				|| null;
		},

		player() { return this.$refs.videoPlyr.player; },
	},

	// methods: {},
	//
	mounted() {
	},
};
</script>

<style lang="sass" scoped>
.video-card
	overflow: hidden

.player
	position: relative
	transform: translateZ(0)
	z-index: 0
	width: 100%
	height: 100%
	transition: opacity .2s ease, transform .2s ease

	// .error &
	// 	display: none

	// .loading &
	// 	opacity: 0

	&::v-deep > .plyr,
	&::v-deep > .plyr > .plyr__video-wrapper
			width: 100%
			height: 100%

	&::v-deep > .plyr video
		display: block
		width: 100%
		height: 100%
		object-fit: cover
</style>
