<template>
	<article
		:class="{
			'article-card_mobile': mobileMode,
			'article-card_desktop': !mobileMode,
		}"
		class="article-card"
	>
		<h3 class="article-card__title">{{ cardData.value.title }}</h3>
		<section class="article-card__text" v-html="cardData.value.text" />
	</article>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'ArticleCard',
	data() {
		return {};
	},
	props: {
		cardData: {
			type: Object,
			required: true,
			validator(data) {
				return data.value.text;
			},
		},
	},
	computed: {
		...mapGetters('browser', ['mobileMode']),
	},
	methods: {
		onLoad(evt) {
			this.loading = false;
		},
		onError(evt) {
			this.error = true;
		},
	},
};
</script>

<style lang="sass">
.article-card
	box-sizing: border-box
	display: flex
	flex-direction: column
	align-items: flex-start
	overflow: hidden
	line-height: $lh__default-text

.article-card_mobile
	justify-content: flex-start
	padding-top: rslv($crd-art__pad-y__mob)
	padding-right: rslv($pad__x__mob)
	padding-bottom: rslv($crd-art__pad-y__mob + $pad__x__mob)
	padding-left: rslv($pad__x__mob)
	font-size: rslv($fs__default-text__mob)

.article-card_desktop
	justify-content: space-between
	padding: rslv($crd-art__pad-y) rslv($pad__x)
	font-size: rslv($fs__default-text)

.article-card__title
	margin: 0
	padding: 0
	font-family: inherit
	font-size: inherit
	line-height: inherit
	flex-shrink: 0

	.article-card_mobile &
		color: $clr__def
		font-weight: bold
		order: 0

	.article-card_desktop &
		font-weight: inherit
		color: $clr__subtitle
		order: 1



.article-card__text
	margin: 0
	padding: 0
	font-family: inherit
	font-size: inherit
	line-height: inherit

	p
		margin: 0
		white-space: normal
		word-break: break-word


</style>
