<template>
	<span class="tag-list">
		<template
			v-for="(tag, index) in tagsToDraw"
		>
			&ensp;<span class="tag-wrapper"
				:key="tag.slug"
				:style="{
					'--separator': `'${separator}'`,
				}"
			>
				<span class="separator">{{separator}}</span>
				&ensp;
				<router-link
					v-if="clickable"
					:to="tag.to"
					class="tag link"
				><span v-html="tag.title" /></router-link>
				<span
					v-else
					class="tag"
					v-html="tag.title"
				/>
			</span>
		</template>
	</span>
</template>

<script>
import { cleanOfBlockTags } from '@/utilites.js';

export default {
	name: 'TagList',

	props: {
		tags: {
			type: Array,
			required: true,
		},

		clickable: Boolean,

		separator: {
			type: String,
			default: '/',
		},
	},

	computed: {
		tagsToDraw() {
			if (!this.tags) return this.tags;
			return this.tags.map(tag => ({
				slug: tag.slug,
				title: cleanOfBlockTags(tag.title),
				to: tag.to || {
					name: 'all-work',
					query: {
						[tag.group]: tag.slug
					},
				},
			}));
		}
	},
}
</script>

<style lang="sass" scoped>
.tag-list
	overflow: hidden
	display: block
	white-space: normal

.tag-wrapper
	--separator: '/'
	margin-right: 1.2em
	margin-left: -1.2em
	white-space: nowrap

.separator
	display: inline-block
	overflow: hidden
	text-align: center
	vertical-align: bottom


.tag
	overflow: hidden
	text-overflow: ellipsis
	white-space: normal


.link
	color: inherit

	&.router-link-exact-active
		text-decoration: none
		color: #6d7278
</style>
