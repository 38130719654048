<template>
	<router-link
		:to="{
			name: 'work',
			params: {
				slug: cardData.meta.slug
			},
			query: route.query
		}"
		:class="{
			mobile: mobileMode,
			desktop: !mobileMode,
		}"
		class="proj-card"
	>
		<pretty-img
			class="cover"
			:src="cardData.image.file"
			:srcset="srcset"
			:alt="title"
			:preload-src="cardData.base64"
			loading="lazy"
		/>

		<span v-if="date" class="date" v-html="date" />

		<h2 class="title" v-html="title" />

		<svg class="arrow"><use xlink:href="#glyph--arrow" href="#glyph--arrow" /></svg>
	</router-link>
</template>

<script>
import { resolveRelativeStaticURL, cleanOfBlockTags, formatDate } from "@/utilites.js";
import { mapState, mapGetters } from "vuex";
import PrettyImg from '@/components/pretty-img';

const THUMBNAILS_KEYS_MAP = {
	'1x1': {
		'1x': 'thumbnail_340x325',
		'2x': 'thumbnail_680x650',
	},
	'2x1':{
		'1x': 'thumbnail_680x325',
		'2x': 'thumbnail_1360x650',
	},
	'1x2':{
		'1x': 'thumbnail_340x810',
		'2x': 'thumbnail_680x1300',
	},
}

function getProjectDates(project) {
	if (!project || !project.project_time) return [];
	return project.project_time.map(timeList => new Date(timeList.time));
}

export default {
	name: "ProjCard",

	components: {
		PrettyImg,
	},

	data() {
		return {
			coverLoaded: false
		};
	},

	props: {
		cardData: {
			type: Object,
			required: true,
		},
	},

	computed: {
		...mapState(["route"]),
		...mapGetters("browser", ["mobileMode"]),

		title() {
			return cleanOfBlockTags(this.cardData.title).replace(
				/^\s*(<br\s?\/?>)+|(<br\s?\/?>)+\s*$/i,
				""
			);
		},


		date() {
			let date = this.cardData.project_time[0];
			if (!date) return null;
			return formatDate(date)
				.split("/")
				.join(`<span style="margin:0 .5em;">/</span>`);
		},

		thumbnails() {
			const size = this.cardData.size;
			const thumbnailsKeys = THUMBNAILS_KEYS_MAP[size];

			if (!thumbnailsKeys) {
				return {};
			}

			const imageMeta = this.cardData.image.meta;

			return Object.keys(thumbnailsKeys).reduce((srcs, code) => {
				const thumbnailKey = thumbnailsKeys[code];
				const thumbnail = imageMeta[thumbnailKey]
				if (!thumbnail) {
					return srcs;
				}
				srcs[code] = thumbnail;
				return srcs;
			}, {});
		},

		srcset() {
			const thumbs = this.thumbnails;
			return Object.keys(thumbs).map(code => `${resolveRelativeStaticURL(thumbs[code].url)} ${code}`).join(', ');
		},
	}
};
</script>

<style lang="sass" scoped>
.proj-card
	will-change: color
	box-sizing: border-box
	position: relative
	z-index: 0
	color: #ffffff
	text-decoration: none
	transition: color .3s ease
	overflow: hidden
	background-color: $clr__dark

.mobile
	padding: 20px 25px 25px

.desktop
	padding: 30px 40px 40px

.arrow
	position: absolute
	width: 80px
	height: 16px

	.mobile &
		bottom: 25px

	.desktop &
		bottom: 40px - 8px

.cover
	will-change: opacity, transform
	position: absolute
	width: 100%
	height: 100%
	top: 0
	right: 0
	bottom: 0
	left: 0
	z-index: -1
	opacity: .6
	transform-origin: center
	transform: scale3d(1.05, 1.05, 1) translateZ(0)
	transition: transform .3s ease-out, opacity .3s ease

	.proj-card:hover &
		opacity: .8
		transform: scale3d(1, 1, 1) translateZ(0)
		transition: transform 5s cubic-bezier(.3,.7,.3,1), opacity 1s ease

.title
	margin: 0

	.mobile &
		@extend %h2-mobile-card-title

		&:first-child
			margin-top: 25px

	.desktop &
		@extend %h2-desktop

		&:first-child
			margin-top: 44px

.date
	display: block

	.mobile &
		@extend %p-mobile

	.desktop &
		@extend %p-desktop
		margin-bottom: 10px
</style>
