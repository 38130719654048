<template>
	<component
		:is="cardMapping[tile.type]"
		:card-data="tile.data"
	/>
</template>

<script>
import TagsCard from '@/components/all-work/tags-card.vue';
import ProjectCard from '@/components/project-card.vue';
import ImageCard from '@/components/image-card.vue';
import ArticleCard from '@/components/article-card.vue';
import VideoCard from '@/components/video-card.vue';
import AllProjectsCard from '@/components/all-projects-card.vue';
import PdfDownloadCard from '@/components/pdf-download-card.vue';

const cardMapping = {
	'filter-card': TagsCard,
	'project-card': ProjectCard,
	'image': ImageCard,
	'text': ArticleCard,
	'video': VideoCard,
	'all-projects-card': AllProjectsCard,
	'pdf-download-card': PdfDownloadCard,
};

export default {
	name: 'GridLayoutTile',
	data() {
		return {
			cardMapping,
		};
	},

	props: {
		tile: {
			type: Object,
			required: true,
			validator(value) {
				return value.type;
			},
		},
	},
};
</script>
