<template>
	<div
		class="image-card"
	>

		<pretty-img
			class="image-card__image"
			:src="cardData.value.image.file"
			:srcset="srcset"
			:alt="cardData.value.image.title || ''"
			:preload-src="cardData.value.base64"
			loading="lazy"
		/>
	</div>
</template>

<script>
import PrettyImg from '@/components/pretty-img'
import { resolveRelativeStaticURL } from '@/utilites.js';
const THUMBNAILS_KEYS_MAP = {
	'1x1': {
		'1x': 'thumbnail_340x405',
		'2x': 'thumbnail_680x810',
	},
	'2x1':{
		'1x': 'thumbnail_680x405',
		'2x': 'thumbnail_1360x810',
	},
	'1x2':{
		'1x': 'thumbnail_340x810',
		'2x': 'thumbnail_680x1620',
	},
}


export default {
	name: 'ImageCard',

	components: {
		PrettyImg,
	},

	props: {
		cardData: {
			type: Object,
			required: true
		},
	},

	computed: {
		thumbnails() {
			const size = this.cardData.value.size;
			const thumbnailsKeys = THUMBNAILS_KEYS_MAP[size];

			if (!thumbnailsKeys) {
				return {};
			}

			const imageMeta = this.cardData.value.image.meta;

			return Object.keys(thumbnailsKeys).reduce((srcs, code) => {
				const thumbnailKey = thumbnailsKeys[code];
				const thumbnail = imageMeta[thumbnailKey]
				if (!thumbnail) {
					return srcs;
				}
				srcs[code] = thumbnail;
				return srcs;
			}, {});
		},

		srcset() {
			const thumbs = this.thumbnails;
			return Object.keys(thumbs).map(code => `${resolveRelativeStaticURL(thumbs[code].url)} ${code}`).join(', ');
		},
	},
};
</script>


<style lang="sass">
.image-card
	position: relative
	z-index: 0
	transform: translateZ(0)
	overflow: hidden

.image-card__preload-image
	position: absolute
	top: -5%
	right: -5%
	bottom: -5%
	left: -5%
	z-index: 0
	width: 110%
	height: 110%
	filter: blur(12px)
	object-fit: cover

.image-card__image

	width: 100%
	height: 100%

</style>
