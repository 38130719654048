<template>
	<router-link
		:to="to"
		:class="{
			mobile: mobileMode,
			desktop: !mobileMode,
		}"
		class="all-projects-card"
	>
		<h2
			class="card-title"
			v-html="$t('see_all_our_work')"
		/>
		<span class="details">{{ projects.length }}</span>
	</router-link>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ALL_PROJECTS_ROUTE_NAME } from '@/constants/router-strings';

export default {
	name: 'AllProjectsCard',

	props: {
		cardData: {
			type: Object,
			default: null,
		},
	},

	computed: {
		...mapState('projects', { projects: 'list' }),
		...mapState(['route']),
		...mapGetters('browser', ['mobileMode']),

		to() {
			return {
				name: ALL_PROJECTS_ROUTE_NAME,
				params: {
					lang: this.route.params.lang,
				},
			};
		},
	},
};
</script>

<style lang="sass" scoped>
.all-projects-card
	position: relative
	overflow: hidden
	color: inherit
	background-color: #f5f5f5
	text-decoration-color: transparent

	&:hover
		text-decoration-color: currentColor


	&.mobile
		display: flex
		align-items: center
		justify-content: space-between
		padding: 0 25px
		box-sizing: border-box
		height: 75px

	&.desktop
		padding: 30px 40px 40px


.card-title
	margin: 0

	.mobile &
		@extend %h2-mobile

	.desktop &
		@extend %h2-desktop

.details
	.mobile &
		@extend %p-mobile

	.desktop &
		@extend %p-desktop
		position: absolute
		left: 40px
		bottom: 35px
</style>
