<template>
	<div
		class="pretty-img"
		role="img"
		:aria-label="alt"
	>
		<img :src="preloadSrc" :alt="alt" class="pretty-img__preloading-img" >
		<picture
			v-show="!isError"
			:class="[
				'pretty-img__pic',
				{ 'pretty-img__pic_loading': isLoading },
				{ 'pretty-img__pic_error': isError },
			]"
		>
			<img
				ref="image"
				class="pretty-img__img"
				:srcset="srcset"
				:alt="alt"
				:src="src"
				v-bind="$attrs"
				@load="handleLoad"
				@loadstart="handleLoadstart"
				@error="handleError"
			>
		</picture>

		<transition name="loader-fade">
			<circle-rotator
				v-if="isLoading && !isError"
				:size="27"
				:width="4"
				:shrinking-time="1.5"
				:rotation-time="2"
				class="pretty-img__loader"
			/>
		</transition>
	</div>
</template>

<script>
import CircleRotator from '@/components/circle-rotator';


export default {
	name: 'PrettyImg',

	components: {
		CircleRotator,
	},

	props: {
		src: String,
		srcset: String,
		alt: String,
		preloadSrc: String,
	},

	data() {
		return {
			isLoading: false,
			isError: null,
		};
	},

	methods: {
		handleLoad(event) {
			this.isLoading = false;
			this.isError = null;
		},

		handleError(event) {
			this.isError = event;
			this.isLoading = false;
		},

		handleLoadstart(event) {
			this.isError = null;
			this.isLoading = true;
		},
	},

	mounted() {
		const img = this.$refs.image;
		if (img.complete) {
			this.handleLoad();
		} else {
			this.handleLoadstart();
		}
	},
};
</script>


<style lang="sass" scoped>
.pretty-img
	position: relative
	transform: translateZ(0)
	overflow: hidden


.pretty-img__preloading-img
	position: absolute
	top: -5%
	right: -5%
	bottom: -5%
	left: -5%
	z-index: 0
	width: 110%
	height: 110%
	filter: blur(12px)
	object-fit: cover

.pretty-img__pic
	position: relative
	z-index: 1
	width: 100%
	height: 100%
	display: block
	object-fit: cover
	transition: opacity .5s ease

.pretty-img__pic_loading
	opacity: 0


.pretty-img__img
	width: 100%
	height: 100%
	object-fit: cover

.pretty-img__loader
	position: absolute
	top: calc((100% - 27px) / 2)
	left: calc((100% - 27px) / 2)
	display: block
	width: 27px
	height: 27px

	color: #ffffff
	opacity: .5

.loader-fade-enter-active
	transition: opacity .2s ease .5s
.loader-fade-leave-active
	transition: opacity .2s ease

.loader-fade-enter,
.loader-fade-leave-to
	opacity: 0
</style>
